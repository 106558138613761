import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { CloseSquare } from 'iconsax-react';
import { Button } from 'components';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  closing: boolean;
  header?: string | React.ReactNode;
  onClose: () => void;
  footer?: React.ReactNode;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  scrollable?: boolean;
};

const ModalBase = ({ closing, header, children, size, onClose, footer, className, scrollable = true }: Props) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    setInit(true);
  }, []);

  return (
    <div className={classnames('modal-base', { init, closing }, className)}>
      <div className="backdrop" onClick={() => onClose()} />

      <div className={classnames('container', size)}>
        <div className="modal-header">
          {!!header && typeof header === 'string' ? <h6>{header}</h6> : header}
          <Button icon={<CloseSquare />} onClick={() => onClose()} variant="alternative" />
        </div>
        <div
          className={classNames('modal-body', {
            scrollable
          })}
        >
          {children}
        </div>
        {!!footer && <div className="modal-footer">{footer}</div>}
      </div>
    </div>
  );
};

export default ModalBase;
