import React from 'react';
import { Copy } from 'iconsax-react';
import { useToast } from 'hooks/useToast';
import { Button } from 'components';

type Props = {
  value: string | number;
};

const TextCopy = ({ value }: Props) => {
  const notify = useToast();

  const handleCopy = async () => {
    await navigator.clipboard.writeText(value as string);
    notify('Copied to clipboard');
  };

  return (
    <div className="text-copy">
      {value}
      <div>
        <Button variant="secondary" icon={<Copy />} tiny onClick={handleCopy} />
      </div>
    </div>
  );
};

export default TextCopy;
