/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { values, cloneDeep } from 'lodash';
import { Warning2 } from 'iconsax-react';
import { confirmReallocateMessage } from 'constants/messages';
import endpoints from 'constants/endpoints';
import { useModal } from 'hooks/useModal';
import useApi from 'hooks/useApi';
import useFormHandler from 'hooks/useFormHandler';
import useTable from 'hooks/useTable';
import { Button, DynamicForm, Table, Input, Alert } from 'components';
import { ModalsList, Props } from 'types/modals';
import { TableOptions, ComponentList } from 'types/table';
import { TextInputTypes } from 'types/dynamicInputs';
import Base from '../../base';
import schema from './schema';

const registeredTableOptions: TableOptions = {
  tableKey: 'allocations',
  columns: [
    { id: 'user_id', header: 'ID #', type: ComponentList.TEXT, width: '110px' },
    { id: 'legal_first_name', header: 'First name', type: ComponentList.TEXT, width: '140px' },
    { id: 'legal_last_name', header: 'Last name', type: ComponentList.TEXT, width: '140px' },
    { id: 'email', header: 'Email', type: ComponentList.TEXT },
    { id: 'has_job', header: 'Allocated', type: ComponentList.YES_OR_NO, width: '110px' }
  ],
  sort: {
    column: 'id',
    order: 'asc'
  },
  itemsPerPage: 100,
  hideFooter: true
};

const allocatedTableOptions: TableOptions = cloneDeep(registeredTableOptions);
allocatedTableOptions.columns.pop();

enum FilterBy {
  'REGISTERED' = 'registered',
  'ALLOCATED' = 'allocated'
}

const filterFc =
  (search: string) =>
  ({ legal_first_name, legal_last_name, email = '' }: any) => {
    const aux = {
      legal_first_name: legal_first_name.toLowerCase(),
      legal_last_name: legal_last_name.toLowerCase(),
      email
    };
    return values(aux).some(el => el.includes(search.toLowerCase()));
  };

const AllocationsModal = ({ closing, onClose, params }: Props) => {
  const modal = useModal();
  const api = useApi();
  const formHandler = useFormHandler(schema);
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState([...params.assigned]);
  const [filterBy, setFilterBy] = useState(FilterBy.REGISTERED);
  const [isExceeded, setIsExceeded] = useState(false);
  const items = api.response?.items || [];

  const allocatedTableHandler = useTable({
    alternative: true,
    options: {
      ...allocatedTableOptions,
      data: search ? params.assigned.filter(filterFc(search)) : params.assigned
    }
  });

  const registeredTableHandler = useTable({
    alternative: true,
    options: {
      ...registeredTableOptions,
      data: search ? items.filter(filterFc(search)) : items
    },
    selected: { value: selected, change: setSelected }
  });

  const handleSave = () => {
    formHandler.setIsSubmitting(true);
    const employees = selected.map((item: any) => +item.id);

    const assignedFlag = params.checkAlreadyAssigned(
      'staff',
      selected.map((item: any) => item.id)
    );
    const data = { employees, workersNeeded: formHandler.data.workersNeeded };

    if (assignedFlag) {
      modal.open(
        ModalsList.CONFIRM_MODAL,
        flag => {
          flag ? onClose(data) : formHandler.setIsSubmitting(false);
        },
        confirmReallocateMessage
      );
    } else {
      onClose(data);
    }
  };

  const handleRemove = () => {
    params.handleRemove();
    onClose();
  };

  useEffect(() => {
    formHandler.setFullForm({
      workersNeeded: params.workersNeeded
    });
    api.fetchData(endpoints.GET_EVENT_SUBSCRIBED.get({ id: params.eventId, paginated: false }), {});
  }, []);

  useEffect(() => {
    const diff = params.workersNeeded - formHandler.data.workersNeeded + params.positionCapacity;
    if (Math.sign(diff) === -1) {
      setIsExceeded(true);
    } else {
      setIsExceeded(false);
    }
  }, [formHandler.data.workersNeeded, params.positionCapacity]);

  return (
    <Base
      className="modal-table allocations-modal"
      header={
        <div className="search-header">
          <h6>
            {params.standName} - {params.positionName}
          </h6>
          <Input type={TextInputTypes.TEXT} placeholder="Search employees.." value={search} onChange={setSearch} />
        </div>
      }
      closing={closing}
      onClose={onClose}
      size="lg"
      footer={
        <>
          <Button text="Remove position" onClick={handleRemove} variant="secondary" />
          <Button text="Save changes" onClick={handleSave} disabled={!formHandler.valid || formHandler.isSubmitting} />
        </>
      }
    >
      <div className="form">
        <DynamicForm handler={formHandler} />
        {isExceeded && (
          <Alert
            type="warning"
            icon={<Warning2 size={20} />}
            message="The total number of positions exceeds the number of positions available"
          />
        )}
      </div>

      <div className="buttons">
        <Button
          text="All workers registered"
          small
          variant={filterBy === FilterBy.REGISTERED ? 'primary' : 'secondary'}
          onClick={() => setFilterBy(FilterBy.REGISTERED)}
        />
        <Button
          text="Allocated workers"
          variant={filterBy === FilterBy.REGISTERED ? 'secondary' : 'primary'}
          small
          onClick={() => setFilterBy(FilterBy.ALLOCATED)}
        />
      </div>

      <Table handler={filterBy === FilterBy.REGISTERED ? registeredTableHandler : allocatedTableHandler} />
    </Base>
  );
};

export default AllocationsModal;
